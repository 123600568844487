.login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin-top: -3rem;
}

.login-box {
  width: max-content;
  background-image: url(../../../../assets/thumb-3-1920-357188.jpg);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 2rem;
}

.login-box h1 {
  font-size: 20px;
  color: white;
  margin-top: -1rem;
  margin-bottom: 0.5rem;
}

.login-card {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 1rem;
  margin-left: 2rem;
}

.login-form h6 {
  width: 280px;
  margin-bottom: 1rem;
}

.login-form p {
  margin-bottom: 3px;
  color: rgb(5, 5, 51);
}

/* .login-form input {
  width: 300px;
  margin-bottom: 10px;
  background-color: transparent;
  border: 1px solid navy;
  border-radius: 5px;
  padding-left: 5px;
} */

.errmsg {
  background-color: red;
  color: white;
  padding: 0.5rem 5.4rem;
}

.login__signInButton {
  background: var(--color-bg);
  border-radius: 5px;
  width: 300px;
  height: 30px;
  border: 1px solid navy;
  margin-top: 10px;
  margin-bottom: 20px;
  color: white;
  cursor: pointer;
}

.login__toRegister {
  display: flex;
  flex-direction: row;
  width: 300px;
  justify-content: center;
  align-items: center;
}
