.testSite__blog {
  display: flex;
  flex-direction: column;
  margin-left: 4rem;
  margin-right: 4rem;
  margin-bottom: 6rem;
}

.testSite__blog-heading {
  width: 100%;
  text-align: left;

  margin-bottom: 2rem;
}

.testSite__blog-heading div {
  width: 38px;
  height: 3px;
  background: var(--gradient-bar);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 0.25rem;
}

.testSite__blog-heading h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: -0.04em;
  color: #fff;
  margin-bottom: 2rem;
}

.testSite__blog-container {
  display: flex;
  flex-direction: row;
}

.testSite__blog-container_groupA {
  flex: 0.75;
  margin-right: 2rem;
  height: 100%;
}

.testSite__blog-container_groupB {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}

@media screen and (max-width: 990px) {
  .testSite__blog-container {
    flex-direction: column-reverse;
  }
}
