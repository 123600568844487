.upload {
  min-width: max-content;
  margin-bottom: 0.2rem;
}

.upload__title {
  /* ff 3.6+ */
  background: -moz-linear-gradient(
    90deg,
    rgba(15, 0, 255, 1) 0%,
    rgba(39, 125, 200, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(
    90deg,
    rgba(15, 0, 255, 1) 0%,
    rgba(39, 125, 200, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-linear-gradient(
    90deg,
    rgba(15, 0, 255, 1) 0%,
    rgba(39, 125, 200, 1) 100%
  );

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#277DC8', endColorstr='#0F00FF', GradientType=0 );

  /* ie 10+ */
  background: -ms-linear-gradient(
    90deg,
    rgba(15, 0, 255, 1) 0%,
    rgba(39, 125, 200, 1) 100%
  );

  /* global 94%+ browsers support */
  background: linear-gradient(
    90deg,
    rgba(15, 0, 255, 1) 0%,
    rgba(39, 125, 200, 1) 100%
  );
}

.upload__title p {
  font-weight: 600;
  color: white;
  font-family: var(--font-family);
  word-spacing: 0.2em;
  margin-bottom: -0.3rem;
}

.upload__dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 2rem;
}

.upload__dialog p {
  font-size: 20px;
  margin-bottom: 1rem;
}

.upload__dialog input {
  margin-left: 3.5rem;
}

.upload__dialog-blank {
  margin-bottom: 1rem;
}

.upload__dialog-subtext {
  font-family: var(--font-family);
  font-size: 12px;
  /* margin-left: 5rem; */
  font-weight: 500;
}
