.register {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin-top: -3rem;
}

.register-box {
  width: max-content;
  background-image: url(../../../../assets/thumb-3-1920-357188.jpg);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
}

.register-box h1 {
  font-size: 20px;
  margin-top: -1rem;
  color: white;
}

.register-card {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.register-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 1rem;
  margin-left: 2rem;
}

.register-form h6 {
  width: 280px;
  margin-bottom: 1rem;
}

.register-form p {
  margin-bottom: 3px;
  color: rgb(5, 5, 51);
  font-size: 30px;
}

.register-form h4 {
  margin-bottom: 3px;
  color: rgb(5, 5, 51);
  font-size: 13.5px;
  font-weight: 450;
}

.form-text {
  margin-bottom: 3px;
  color: rgb(5, 5, 51);
  font-size: 18px;
}

/* .register-form input {
  width: 300px;
  margin-bottom: 10px;
  background-color: transparent;
  border: 1px solid navy;
  border-radius: 5px;
  padding-left: 5px;
} */

.register p {
  font-size: 11px;
  color: rgb(3, 3, 66);
  font-size: 200;
}

.errmsg {
  background-color: red;
  color: white;
  padding: 0.5rem 5.4rem;
}

.register__registerButton {
  background: var(--color-bg);
  border-radius: 5px;
  width: 300px;
  height: 30px;
  border: 1px solid navy;
  margin-top: 10px;
  margin-bottom: 4px;
  color: white;
  cursor: pointer;
}

.register__toLogin {
  background: white;
  border-radius: 5px;
  width: 300px;
  height: 30px;
  border: 1px solid rgb(3, 3, 66);
  margin-top: 10px;
  margin-bottom: 2px;
  margin-top: 0;
  cursor: pointer;
}
