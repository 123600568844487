.module-table {
  display: flex;
  padding-top: 7.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 15px;
  width: 99%;
}

.module {
  background: transparent;
  height: 100vw;
  width: 100%;
  background-size: cover;
  display: flex;
}

.ant-segmented-item {
  border-radius: 10px;
}
.ant-segmented-item-selected {
  border-radius: 10px;
}

.ant-segmented-thumb {
  border-radius: 10px;
}

.edit-input {
  border: 1.25px solid;
  border-color: rgb(182, 180, 180);
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  font-size: 14px;
  letter-spacing: 0.03em;
  height: 30px;
  width: 120px;
  background-color: transparent;
}

.add-input {
  border: 1.25px solid;
  border-color: rgb(182, 180, 180);
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  font-size: 14px;
  letter-spacing: 0.03em;
  height: 30px;
  width: 250px;
  background-color: transparent;
}

.switch-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0.6rem;
}

.option {
  color: black !important;
}

.option-disabled {
  color: grey !important;
}

.ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.grid-horizontal {
  display: flex;
  flex-direction: row;
  padding: 5px 5px;
}

.filters-first_column {
  display: flex;
  justify-content: flex-end;
  width: 350px;
  padding-top: 0.3rem;
  color: rgb(8, 8, 46);
  font-weight: 500;
}
