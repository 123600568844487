.navigation {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 5;
  padding: 0.5rem 3rem;
  background: #02081b;
  height: 60px;
}

.navigation-light {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 5;
  padding: 0.5rem 3rem;
  background: #e9eaec;
  height: 60px;
}

.navigation-tab {
  margin-top: -1rem;
  margin-right: 2rem;
}

.navigation-tab-light {
  margin-top: -1rem;
  margin-right: 2rem;
  color: #02081b;
}

.project__search {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  margin-right: 1rem !important;
  color: white;
  align-items: center;
}

.project__searchInput {
  border-radius: 15px;
  border: none !important;
  background: transparent;
  line-height: 40px;
  padding-left: 20px;
  font-size: 15px;
  margin-left: 0.5rem;
  width: 50%;
  max-width: 250px;
  color: black !important;
  height: 25px !important;
  /* margin-bottom: 0.4rem; */
}

.project__search-icon {
  margin-bottom: 0.3rem;
  color: black;
}

.ant-select-selection.ant-select-selection--single {
  background: transparent !important;
}

.select-project .ant-select .ant-select-selector {
  border: 1.5px solid;
  border-radius: 5px;
  border-color: transparent;
  background-color: transparent;
  color: white !important;
  min-width: 200;
  display: flex;
  justify-content: flex-end !important;
}

.project-option {
  color: black !important;
}

.navigation__links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
  z-index: 5;
  margin-left: -2rem;
}

.navigation__links-logo {
  margin-left: 1rem;
  margin-right: 1rem;
  object-fit: contain;
  margin-top: -1rem;
  z-index: 5;
}

.navigation__links-logo img {
  width: 25%;
  height: 25%;
  z-index: 5;
}

.navigation__links-container {
  display: flex;
  flex-direction: row;
  /* margin-right: 12rem; */
  z-index: 5;
}

.navigation__sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  z-index: 5;
}

.navigation__links-container a,
.navigation__sign p,
.navigation__menu-container {
  color: #fff;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  text-transform: capitalize;
  word-spacing: 0.3em;
  z-index: 5;
  margin: 0 1rem;
  cursor: pointer;
}

.drawer-header-text {
  font-size: 18px;
  margin-bottom: -0.3rem;
  margin-left: 0.45rem;
  color: #02081b;
  z-index: 5;
  display: flex;
  justify-content: center;
}

.drawer-header-flex {
  display: flex;
  align-items: center;
  z-index: 5;
}

.user {
  display: flex;
  flex-direction: row;
  width: max-content;
  color: #fff;
  /* margin-right: 1rem; */
  margin-top: 28px;
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 500;
  z-index: 5;
}

.project {
  color: #fff;
  margin-left: 2rem;
  display: flex;
  width: max-content;
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 500;
  z-index: 5;
}

.navigation__sign button,
.navigation__menu-container button {
  padding: 0.5rem 1rem;
  color: #fff;
  left: 1153px;
  top: 55px;
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 500;
  background: transparent;
  border-radius: 5px;
  border: 0;
  outline: none;
  cursor: pointer;
  word-spacing: 0.3em;
  margin-left: 2rem;
  z-index: 5;
}

.navigation__menu {
  margin-left: 1rem;
  z-index: 5;
  display: none;
  position: relative;
}

.navigation__menu svg {
  cursor: pointer;
  z-index: 5;
}

.navigation__menu-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  z-index: 5;
  text-align: end;
  background: var(--color-footer);
  padding: 2rem;
  position: absolute;
  top: 40px;
  right: 0;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: none;
  list-style: none;
  text-align: center;
  justify-content: end;
  margin-right: 2rem;
  z-index: 5;
}

.nav-item {
  display: flex;
  align-items: center;
  margin-right: 3rem;
  z-index: 5;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  text-transform: capitalize;
  word-spacing: 0.3em;
  z-index: 5;
}

.nav-links:hover {
  color: #fff;
  background-color: #1888ff;
  border-radius: 5px;
  transition: all 0.2s ease-out;
  z-index: 5;
}

.navigation__menu-container p {
  margin: 1rem 0;
  z-index: 5;
}

.navigation__menu-container__links-sign {
  display: none;
  z-index: 5;
}

.navigation__project {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  text-transform: capitalize;
  word-spacing: 0.3em;
  margin-left: 2rem;
  z-index: 5;
}

.navigation__settings {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  color: #fff;
  margin-left: 0.6rem;
  margin-right: -7rem;
  z-index: 5;
}

.navigation__settings-menu {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  text-align: end;
  /* background: #02081b; */
  /* border: 1px solid #1888ff; */
  padding: 2rem;
  position: absolute;
  margin-top: 0.5rem;
  z-index: 5;
}

.navigation__settings-menu h3 {
  color: #02081b;
  padding-bottom: 3.5rem;
  padding-top: -3rem;
  margin-left: 1.8rem;
  z-index: 5;
}

.navigation__settings-menu_links {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  font-size: 17px;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  margin-left: 1rem;
  z-index: 5;
}

.navigation__settings-menu_links-second {
  display: flex;
  flex-direction: row;
  margin-top: -0.5rem;
  margin-bottom: 1.7rem;
  font-size: 17px;
  justify-content: center;
  align-items: center;
  margin-right: 0.3rem;
  margin-left: 1rem;
  color: #02081b;
  z-index: 5;
}

.navigation__settings-menu_links:last-child {
  margin-bottom: 0.2rem;
  z-index: 5;
}

.icon {
  margin-right: 1rem;
  margin-top: -0.85rem;
  margin-bottom: 0.8rem;
  color: white;
  z-index: 5;
}

.icon__second {
  margin-right: 1rem;
  margin-top: -0.85rem;
  margin-bottom: 0.8rem;
  color: #ffffff;
  z-index: 5;
}

.icon__link {
  margin-top: -0.85rem;
  margin-bottom: 0.8rem;
  margin-left: 0.5rem;
  color: #02081b;
  z-index: 5;
}

.icon__first {
  /* margin-top: 0.5rem; */
  margin-right: -0.8rem;
  color: white;
  z-index: 5;
}

.button_en {
  display: flex !important;
  margin-left: 3rem;
  z-index: 5;
}

.button_gr {
  display: flex !important;
  margin-left: 2.3rem;
  z-index: 5;
}

.bv {
  margin-right: 22px;
  z-index: 5;
}

.settings {
  color: #ffffff;
  z-index: 5;
}

.notifications {
  color: #ffffff;
  z-index: 5;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@media screen and (max-width: 1060px) {
  .navigation__links-container {
    /* display: none; */
    z-index: 5;
  }

  .logo-text {
    display: none;
  }
}

@media screen and (max-width: 1035px) {
  .navigation__links-container {
    /* display: none; */
    z-index: 5;
  }

  .logo-text {
    display: none;
  }

  .user {
    display: none;
  }

  .navigation__links-logo {
    margin-left: 1rem;
    margin-right: -5rem;
    object-fit: contain;
    margin-top: -2rem;
    z-index: 5;
  }

  .navigation__menu {
    display: flex;
    z-index: 5;
  }
}

@media screen and (max-width: 700px) {
  .navigation {
    padding: 2rem 4rem;
    z-index: 5;
  }
}

@media screen and (max-width: 550px) {
  .navigation {
    padding: 2rem;
    z-index: 5;
  }

  .navigation__sign {
    display: none;
    z-index: 5;
  }

  .navigation__menu-container {
    top: 20px;
    z-index: 5;
  }

  .navigation__menu_container__links-sign {
    display: block;
    z-index: 5;
  }
}
