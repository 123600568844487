.testSite__header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.testSite__header-content {
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-top: -22rem;
  /* margin-right: 3rem; */
}

.header-form {
  display: flex;
  flex: 0.5;
  justify-content: center;
  /* margin-left: 10rem;
  margin-right: -5rem; */
}

.horizontal-flex {
  display: flex;
  flex-direction: row;
}

.ant-select-selection {
  color: transparent;
  background-color: transparent !important;
}
.ant-select-item {
  color: transparent !important;
  background-color: transparent !important;
}

.ant-select-item-option-active {
  background-color: transparent !important;
}

.testSite__header-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 50px;
  line-height: 75px;
  letter-spacing: -0.04em;
  word-spacing: 0.1em;
}

.testSite__header-content p {
  font-family: var(--font-family);
  font-size: 20px;
  line-height: 27px;

  color: var(--color-text);
  margin-top: 1.5rem;
  word-spacing: 0.2em;
}

.testSite__header-content__input {
  width: 100%;
  margin: 2rem 0 1rem;
  display: flex;
}

.testSite__header-content__input input {
  flex: 2;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-size: 20px;
  line-height: 27px;
  background: var(--color-footer);
  border: 2px solid var(--color-footer);
  padding: 0 1rem;
  outline: none;
  color: #fff;

  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.testSite__header-content__input button {
  flex: 0.6;
  width: 100%;
  min-height: 50px;
  background: #3360f7;
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #fff;
  cursor: pointer;
  outline: none;
  padding: 0 1rem;
  border: none;

  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* .testSite__header-content__people {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-top: 2rem;
} */

/* .testSite__header-content__people img {
  width: 181.79px;
  height: 38px;
} */

/* .testSite__header-content__people p {
  margin: 0 0 0 1rem;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 38px;
  color: #fff;
  text-align: center;
} */

.testSite__header-image {
  flex: 0.75;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.testSite__header-image img {
  width: 220px;
  height: 260px;
}

@media screen and (max-width: 1050px) {
  .testSite__header {
    flex-direction: column;
  }

  .testSite__header-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .testSite__header h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .testSite__header p {
    font-size: 16px;
    line-height: 24px;
  }

  .testSite__header-content__people {
    flex-direction: column;
  }

  .testSite__header-content__people p {
    margin: 0;
  }

  .testSite__header-content__input input,
  .testSite__header-content__input button {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .testSite__header h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .testSite__header p {
    font-size: 14px;
    line-height: 24px;
  }

  .testSite__header-content__input input,
  .testSite__header-content__input button {
    font-size: 12px;
    line-height: 16px;
  }
}
