.ant-select-selection-selected-value {
  width: 100%;
}

.chart__menu {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 30px;
  justify-content: center;
  z-index: 0;
}

.chart__menu-item {
  z-index: 0;
}

.buttons {
  margin-top: -0.4rem;
  margin-bottom: -0.5rem;
}

.buttons-vertical {
  margin-top: 10;
  margin-bottom: 0.5rem;
  margin-left: 1.5rem;
}

.prntg {
  color: gray;
  margin-left: 0.4rem;
}

.count-horizontal-flex {
  display: flex;
  justify-content: center;
}

.bar-opt {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bar-opt-end {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.bar-opt_chart {
  display: flex;
  align-items: center;
  width: 100%;
}

.chart__options {
  display: flex;
  flex-direction: column;
  /* margin-top: 2rem;
  padding-left: 4rem; */
  padding-bottom: 0.8rem;
  width: 100%;
}

.chart__options-1 {
  flex: 0.2;
}

.chart__options-2 {
  flex: 0.8;
}

.chart__options-angles {
  display: flex;
  flex-direction: column;
}
.chart__options-angles p {
  color: rgb(84, 190, 243);

  font-weight: 470;
  font-size: 16px;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
}

.chart {
  display: flex;
  height: max-content;
  padding-top: 7.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 15px;
  width: 99%;
}

.ant-select-selection-placeholder {
  color: #000;
}

.chart__display {
  background: transparent;
  display: flex;
  justify-content: flex-start;
  height: 30vw;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media screen and (min-width: 1750px) {
  .chart__menu {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 1050px) {
  .chart__menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .chart__menu {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .chart__menu {
    padding: 2rem;
  }
}
