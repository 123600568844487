* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* scroll-behavior: smooth; */
}

body {
  /* ff 3.6+ */
  background: -moz-linear-gradient(
    180deg,
    rgba(5, 12, 38, 1) 0%,
    rgba(18, 25, 50, 1) 75%,
    rgba(45, 51, 78, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(
    180deg,
    rgba(5, 12, 38, 1) 0%,
    rgba(18, 25, 50, 1) 75%,
    rgba(45, 51, 78, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-linear-gradient(
    180deg,
    rgba(5, 12, 38, 1) 0%,
    rgba(18, 25, 50, 1) 75%,
    rgba(45, 51, 78, 1) 100%
  );

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2D334E', endColorstr='#050C26', GradientType=1 );

  /* ie 10+ */
  background: -ms-linear-gradient(
    180deg,
    rgba(5, 12, 38, 1) 0%,
    rgba(18, 25, 50, 1) 75%,
    rgba(45, 51, 78, 1) 100%
  );

  /* global 94%+ browsers support */
  background: linear-gradient(
    180deg,
    rgba(5, 12, 38, 1) 0%,
    rgba(18, 25, 50, 1) 75%,
    rgba(45, 51, 78, 1) 100%
  );
}

a {
  color: unset;
  text-decoration: none;
}

/* width */
::-webkit-scrollbar {
  height: 6px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #908e8e;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #908e8e;
  border-radius: 10px;
}

.gradient__bg {
  background-image: url(./assets/curved.jpg);
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: repeat;
  padding-bottom: 20px;
  overflow-y: hidden;
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2 rem;
  }
}
