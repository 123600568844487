.table {
  table-layout: fixed;

  .tableHead {
    user-select: none;
  }
  .tableCell {
    border-right: 1px solid rgb(211, 211, 211) !important;
    padding-left: 5;
    padding-right: 5;
    &.resizable {
      position: relative;
    }
    &.emptyCell {
      width: "auto";
    }
  }

  .resizeLine {
    position: absolute;
    height: 100%;
    width: 4px;
    top: 0;
    right: -2px;
    cursor: col-resize;
    &:hover {
      background-color: #0aa1dd;
    }
    &:active {
      background-color: #0aa1dd;
    }
  }
}

.testing_box {
  display: flex;
  flex-direction: row;
}

.testing_table {
  display: flex;
  flex-direction: row;
}

.default {
  color: white;
}

.ant-select-selection-placeholder {
  color: grey;
  opacity: 0.5;
  font-weight: 500;
}

.tableCell p {
  font-weight: 700;
  color: rgb(8, 8, 46);
  margin-bottom: -0.3rem;
}

.totals p {
  /* font-size: 10; */
  /* font-weight: 700; */
  margin-bottom: -0.2rem;
}

.background-card {
  background-color: white;
}

.id-card {
  background-color: rgb(8, 8, 46);
  border-radius: 10px;
  width: 250px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  display: flex;
  /* justify-content: center; */
}

.id-card h5 {
  color: white;
  margin-bottom: 1rem;
}

.id-card h1 {
  color: white;
}

.p-title {
  font-size: 11px;
  font-weight: 600;
}

.p-context {
  font-size: 12px;
}

.p-footer {
  font-size: 12px;
  font-style: italic;
}

.p-footer-warning {
  color: rgb(240, 178, 6);
  font-size: 12px;
  font-style: italic;
}

.p-footer-pastdue {
  color: red;
  font-size: 12px;
  font-style: italic;
}

.horizontal-flex {
  display: flex;
  flex-direction: row;
}

.horizontal-line {
  border-top: 2px solid rgb(8, 8, 46);
  width: 150px;
  margin-bottom: 1rem;
  margin-top: 5px;
}

.warning {
  color: rgb(255, 0, 0);
  margin-left: 0.5rem;
}

.very-low {
  color: rgb(52, 129, 14);
  margin-left: 0.5rem;
  font-size: 12px;
}

.low {
  color: rgb(81, 255, 0);
  margin-left: 0.5rem;
  font-size: 12px;
}

.medium {
  color: rgb(237, 150, 74);
  margin-left: 0.5rem;
  font-size: 12px;
}

.high {
  color: rgb(248, 71, 7);
  margin-left: 0.5rem;
  font-size: 12px;
}

.very-high {
  color: rgb(248, 6, 6);
  margin-left: 0.5rem;
  font-size: 12px;
}

.expired {
  color: rgb(248, 6, 6);
}

.soon-to-expire {
  color: rgb(237, 150, 74);
}

.grouping-row {
  background-color: rgb(208, 223, 247);
  color: rgb(8, 8, 46);
  padding-left: 2rem;
}

.dropdown {
  margin-right: 0.6rem;
}

.filters {
  min-width: max-content;
  margin-bottom: 0.2rem;
}

.filters__box {
  display: flex;
  flex-direction: row;
  padding: 2rem 2rem;
}

.filters-row {
  margin-bottom: 0.5rem;
}

.filters-row input {
  border: 1.25px solid;
  border-color: rgb(182, 180, 180);
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 0.5rem;
  margin-right: 1rem;
  margin-left: 0.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  font-size: 14px;
  letter-spacing: 0.03em;
  height: 30px;
  background-color: transparent;
}

.filters-row textarea {
  border: 1.5px solid;
  border-color: rgb(182, 180, 180);
  border-radius: 5px;
  margin-top: 0.5rem;
  margin-right: 1rem;
  margin-left: 0.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  letter-spacing: 0.03em;
  background-color: transparent;
}

.filters-first_column {
  display: flex;
  justify-content: flex-end;
  width: 350px;
  padding-top: 0.3rem;
  color: rgb(8, 8, 46);
  font-weight: 500;
}

.confirm-btn {
  background-color: rgb(8, 8, 46);
  color: white;
}

.check-icon {
  margin-top: 0.1rem;
  margin-right: 0.5rem;
}

.ghost-icon {
  margin-top: 0.1rem;
  margin-right: 0.5rem;
  width: 13px;
}

.ant-modal {
  margin-top: -5.5rem;
}

.menu {
  padding-top: 1rem;
  padding-bottom: 0.8rem;
  padding-left: 0.5rem;
  padding-right: 2rem;
}

.menu-item {
  padding-right: 2rem;
}

/* .menuItem-text {
  padding-right: 2rem;
} */

.datepicker-text {
  margin-top: 0.8rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.select .ant-select .ant-select-selector {
  border: 1.5px solid;
  border-color: rgb(182, 180, 180);
  border-radius: 5px;
  background-color: transparent;
}

.option {
  color: black !important;
}

.switch-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0.6rem;
}

.switch {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0.3rem;
  margin-left: -0.1rem;
  padding-left: 10px;
  padding-right: 10px;
  height: 32px;
  width: max-content;
  border: 1.5px solid;
  border-color: rgb(208, 209, 212);
  border-radius: 25px;
  background-color: white;
}

.switch-icon {
  margin-top: -0.8rem;
  margin-top: 0.1rem;
}

.ant-switch-checked {
  background-color: rgb(150, 184, 238);
}

.switch p {
  margin-right: 0.8rem;
  margin-left: 0.35rem;
  /* margin-top: 0.75rem; */
}

.progress-bar {
  display: flex;
  flex: 0.65;
}

.progress-info {
  display: flex;
  flex: 0.35;
  margin-left: 0.4rem;
}

.progress-info p {
  font-size: 12px;
}

.circle-progress-info {
  margin-top: 1rem;
  margin-left: 0.5rem;
  font-size: 12px;
}

.upload-button {
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  border: 1.5px solid;
  border-color: rgb(182, 180, 180);
  border-radius: 5px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgb(150, 184, 238) !important;
  border-color: rgb(150, 184, 238) !important;
}

.ant-checkbox .ant-checkbox-inner {
  border-color: rgb(150, 184, 238);
}

.selected-info {
  display: flex;
  justify-content: center;
  margin-right: 1rem;
  padding-left: 10px;
  padding-right: 10px;
  height: 32px;
  width: max-content;
  border: 1.5px solid;
  border-color: rgb(180, 203, 241);
  border-radius: 15px;
  background-color: rgb(180, 203, 241);
}

.selected-info p {
  margin-top: 0.2rem;
}

.delete-icon {
  margin-right: 0.5rem;
  background-color: rgb(180, 203, 241);
}

.checkbox-cell {
  min-width: 20px;
}

.infocard-actions {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  justify-content: center;
}

.infocard-btn {
  width: max-content;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.selected-items {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

.selected-items-info {
  font-size: 12px;
  margin-left: 0.5rem;
}

.edit input {
  border: 1.25px solid;
  border-color: rgb(182, 180, 180);
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 0.5rem;
  margin-right: 1rem;
  margin-left: 0.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  font-size: 14px;
  letter-spacing: 0.03em;
  height: 30px;
  background-color: transparent;
}

.edit textarea {
  border: 1.5px solid;
  border-color: rgb(182, 180, 180);
  border-radius: 5px;
  margin-top: 0.5rem;
  margin-right: 1rem;
  margin-left: 0.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  letter-spacing: 0.03em;
  background-color: transparent;
}

.link {
  font-size: 12px;
}

.columnsChanged {
  display: flex;
  justify-content: flex-end;
  width: 350px;
  padding-top: 0.3rem;
  color: rgb(228, 21, 21);
  font-weight: 500;
}

.asterisk {
  color: rgb(228, 21, 21);
  font-size: 18px;
  margin-top: -0.4rem;
  margin-right: 0.3rem;
}

.asterisk-blue {
  color: rgb(21, 97, 220);
  font-size: 18px;
  margin-top: -0.4rem;
  margin-right: 0.3rem;
}

.grid {
  padding: 5px 5px;
}

.grid-horizontal {
  display: flex;
  flex-direction: row;
  padding: 5px 5px;
}

.align-left {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  margin-bottom: -2.8rem;
}

.asterisk-footer {
  color: rgb(228, 21, 21);
  font-size: 18px;
  margin-right: 0.3rem;
  margin-left: 1rem;
}

.asterisk-footer-blue {
  color: rgb(21, 97, 220);
  font-size: 18px;
  margin-right: 0.3rem;
}

.ant-image-preview-operations-operation:not(:first-child) {
  display: none;
}

/* .ant-picker-date-input {
  background-color: rgb(196, 213, 241);
} */

/* .ant-modal-header {
  background-color: rgb(8, 8, 46);
  color: white !important;
} */
