.dropdown-menu {
  width: auto;
  border-radius: 10px;
  position: absolute;
  top: 52px;
  list-style: none;
  text-align: start;
  overflow: hidden;
}

.dropdown-menu li {
  background: #02081b;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: #1888ff;
  color: #fff;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}

.settings-switch {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0.3rem;
  margin-left: -0.1rem;
  padding-left: 10px;
  padding-right: 10px;
  height: 32px;
  width: max-content;
  border: "none";
  border-color: rgb(208, 209, 212);
  border-radius: 25px;
  background-color: white;
}

.switch-icon {
  margin-top: -0.8rem;
  margin-top: 0.1rem;
}

.ant-switch-checked {
  background-color: rgb(150, 184, 238);
}

.settings-switch p {
  margin-right: 0.8rem;
  margin-left: 0.35rem;
  /* margin-top: 0.75rem; */
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
