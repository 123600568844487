.newRegister {
  min-width: max-content;
  margin-bottom: 0.2rem;
}

.newRegister__title {
  /* ff 3.6+ */
  background: -moz-linear-gradient(
    90deg,
    rgba(15, 0, 255, 1) 0%,
    rgba(39, 125, 200, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(
    90deg,
    rgba(15, 0, 255, 1) 0%,
    rgba(39, 125, 200, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-linear-gradient(
    90deg,
    rgba(15, 0, 255, 1) 0%,
    rgba(39, 125, 200, 1) 100%
  );

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#277DC8', endColorstr='#0F00FF', GradientType=0 );

  /* ie 10+ */
  background: -ms-linear-gradient(
    90deg,
    rgba(15, 0, 255, 1) 0%,
    rgba(39, 125, 200, 1) 100%
  );

  /* global 94%+ browsers support */
  background: linear-gradient(
    90deg,
    rgba(15, 0, 255, 1) 0%,
    rgba(39, 125, 200, 1) 100%
  );
}

.newRegister__title p {
  font-weight: 800;
  color: white;
  font-family: var(--font-family);
  word-spacing: 0.2em;
}

.newRegister__box {
  display: flex;
  flex-direction: row;
  padding: 2rem 2rem;
}

.newRegister__box-firstColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
}

.newRegister__box-firstColumn p:first-child {
  margin-top: 0.95rem;
}

.newRegister__box-firstColumn p {
  margin-top: 0.7rem;
}

.newRegister__box-secondColumn {
  display: flex;
  flex-direction: column;
}

.newRegister__box-secondColumn input {
  border: 1.25px solid;
  border-color: rgb(182, 180, 180);
  border-radius: 5px;
  margin-top: 1.4rem;
  margin-bottom: 0.3rem;
  padding: 0.5rem 0.5rem;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  letter-spacing: 0.03em;
  height: 30px;
  background-color: transparent;
}

.newRegister__box-secondColumn input:focus {
  outline: none !important;
  border: 2px solid;
  border-color: #1374db;
}

.newRegister__box-secondColumn textarea {
  border: 1.5px solid;
  border-color: rgb(182, 180, 180);
  border-radius: 5px;
  margin-top: 1rem;
  padding: 0.5rem 0.5rem;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  letter-spacing: 0.03em;
  background-color: transparent;
}

.newRegister__box-secondColumn textarea:focus {
  outline: none !important;
  border-color: #1374db;
}

.newRegister__grid {
  display: grid;
  grid-template-columns: (4, minmax(4, 1fr));
}

.test {
  display: flex;
  flex-direction: column;
  margin-left: 8rem;
}

.test-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
}

.test-row input {
  border: 1.25px solid;
  border-color: rgb(182, 180, 180);
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 0.5rem;
  margin-right: 1rem;
  margin-left: 0.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  font-size: 14px;
  letter-spacing: 0.03em;
  height: 30px;
  background-color: transparent;
}

.test-row input:focus {
  outline: none !important;
  border: 2px solid;
  border-color: #1374db;
}

.test-row textarea {
  border: 1.5px solid;
  border-color: rgb(182, 180, 180);
  border-radius: 5px;
  margin-top: 0.5rem;
  margin-right: 1rem;
  margin-left: 0.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  letter-spacing: 0.03em;
  background-color: transparent;
}

.test-row textarea:focus {
  outline: none !important;
  border-color: #1374db;
}

.test-first_column {
  display: flex;
  justify-content: flex-end;
  width: 350px;
  padding-top: 0.3rem;
}
