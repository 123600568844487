.profile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  padding-top: 4.5rem;
}

.profile-light {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  padding-top: 4.5rem;
}

.profile h1 {
  color: white;
  margin-bottom: 1.5rem;
  font-size: 35px;
  font-weight: 300;
}

.profile-light h1 {
  color: #02081b;
  margin-bottom: 1.5rem;
  font-size: 35px;
  font-weight: 300;
}

.first__box {
  padding: 1.5rem 1.5rem;
  background-image: url(../../assets/thumb-3-1920-357188.jpg);
  border-radius: 10px;
  margin-top: 8.5rem;
}

.second__box {
  padding: 1rem 1rem;
  /* background-color: #02081b; */
  background-color: rgb(8, 8, 46);
  border-radius: 10px;
}

.profile__box {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 2rem 4rem;
  align-items: center;
  margin-top: 8.5rem;
  width: 98vw;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 10px;
}

.profile__box-content {
  display: flex;
  flex-direction: row;
}

.user__preferences {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  justify-items: center; */
  align-items: center;
}

.user__preferences h2 {
  margin-top: 0.5rem;
  margin-bottom: 3rem;
}

.preferences {
  display: flex;
  flex-direction: row;
}

.profile__box-firstColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
}

.profile__box-firstColumn p {
  font-size: 17px;
}

.profile__box-firstColumn p:first-child {
  margin-top: 0.8rem;
}

.profile__box-secondColumn {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
}

.button__pwd {
  margin-top: 2.5rem;
}

.cancel-btn {
  margin-top: 2rem;
}

.vertical__line {
  border-left: 6px solid rgb(8, 8, 46);
  height: 190px;
  margin-left: 4.7rem;
  margin-right: 3rem;
  margin-top: 5.5rem;
}

.vertical__line-gr {
  border-left: 6px solid rgb(8, 8, 46);
  height: 190px;
  margin-left: 3.8rem;
  margin-right: 3rem;
  margin-top: 5.5rem;
}

.password__change {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  justify-items: center; */
  align-items: center;
}

.password__change h2 {
  margin-top: 0.5rem;
  margin-bottom: 3rem;
}

.password {
  display: flex;
  flex-direction: row;
}
