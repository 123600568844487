@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

:root {
  --font-family: "Manrope", sans-serif;

  --gradient-text: linear-gradient(89.97deg, #f4f81b 1.84%, #c75010 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);

  --color-bg: #061935;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #ffffff;
  --color-subtext: #ff8a71;
}
