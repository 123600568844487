.chart-reports {
  display: flex;
  min-width: max-content;
  min-height: max-content;
  margin-bottom: 0.2rem;
}

.chart-reports__title p {
  font-weight: 800;
  color: white;
  font-family: var(--font-family);
  word-spacing: 0.2em;
}

.chart-reports__box {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 0rem 2rem;
  margin-right: 1.5rem;
  margin-left: 5rem;
}

.chart-reports__box-firstColumn p:first-child {
  margin-top: 0.95rem;
}

.chart-reports__box-firstColumn p {
  margin-top: 0.6rem;
}

.chart-reports__box-firstColumn {
  display: flex;
  flex-direction: column;
  width: max-content;
  align-items: flex-end;
  margin-left: -5rem;
  margin-right: 3rem;
  margin-top: 1.3rem;
  flex: 1;
  margin-bottom: -1rem;
  gap: 0.85rem;
}

.chart-reports__box-secondColumn {
  display: flex;
  flex-direction: column;
  margin-top: 1.3rem;
  margin-right: 3rem;
  margin-left: -2rem;
}

.chart-reports__box-secondColumn input {
  border: 1.25px solid;
  border-color: rgb(182, 180, 180);
  border-radius: 5px;
  margin-top: 1rem;
  margin-bottom: 0.3rem;
  padding: 0.5rem 0.5rem;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  letter-spacing: 0.03em;
  height: 30px;
  background-color: transparent;
}

.chart-reports__box-secondColumn input:focus {
  outline: none !important;
  border: 2px solid;
  border-color: #1374db;
}

.chart-reports__grid {
  display: grid;
  grid-template-columns: (4, minmax(4, 1fr));
}

.chart-reports__footer {
  margin-right: 1rem;
}

.chart-report__table {
  margin-top: 1rem;
  margin-right: 7rem;
  height: 40px;
  display: flex;
  flex-direction: row;
}

.chart-reports__dialog {
  display: flex;
  flex-direction: row;
  min-height: 530px;
  overflow: hidden;
}
